import React from 'react'
import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Collapsible from "react-collapsible";
import ReactGA from 'react-ga4'

//Firebase
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword, /*signInWithRedirect, getRedirectResult*/ } from 'firebase/auth';
import { auth, database } from '../firebase/firebaseClient';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

//Stripe
import { createCheckoutSession } from '../stripe/createCheckoutSessions';

//Components
import ProductCheckoutItem from '../Components/ProductCheckoutItem';

//CSS
import "../css/Checkout.css"

//Images
import initialsLogo from '../images/TPSStore.png'
import showPasswordIcon from '../images/showPasswordIcon.png'
import hidePasswordIcon from '../images/hidePasswordIcon.png'
import successIcon from "../images/successIcon.png";
import ProfileBanner from '../Components/ProfileBanner';
import downArrow from '../images/downArrow.png'
import poweredByStripe from '../images/poweredByStripe.svg';

function Checkout() {

    //Auth
    const [isAuthed, setIsAuthed] = useState(false);

    //General
    const [cartTotal, setCartTotal] = useState(0);
    const [processing, setProcessing] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");

    //Form
    const [email, setEmail] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginPasswordVisibility, setLoginPasswordVisibility] = useState('');
    const [orderID, setOrderID] = useState('');

    //Google Sign in
    const [loginSuccess, setLoginSuccessModal] = useState(false);
    const [googlePFP, setGooglePFP] = useState("");
    const [profileRetrieveError, setProfileRetreiveError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    let cartItems = JSON.parse(localStorage.getItem("tps-cart"));


    useEffect(() => {
        
        ReactGA.send({
            hitType: "pageview",
            page: "/checkout",
            title: "Checkout"
        });

        if(!cartItems){
            window.location.href = window.location.origin + "/store";
        }

        let quantity = cartItems.amt;

        const unsub = onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log('VALID USER')
                setIsAuthed(true);
                await fetchUserInformation().then((promise)=>{
                    if (promise === 500) {
                        setProfileRetreiveError(true);
                        ReactGA.event({
                            category: "profile_retrieval_error",
                            action: "Profile retrieval error",
                        });
                    }
                    else {
                        setIsLoading(true);
                    }
                });
            }
            else {
                setIsAuthed(false);

                document.getElementById("email").addEventListener('focus', (e) => {
                    document.getElementById("email").style = "border: 1px solid #E1E1E1; color #000000;";
                    document.getElementById("email").attributes.getNamedItem("placeholder").value = "doe.joe@gmail.com";
                    document.getElementById("email").classList.remove("invalid-form");
                });
    
                document.getElementById("firstName").addEventListener('focus', (e) => {
                    document.getElementById("firstName").style = "border: 1px solid #E1E1E1";
                    document.getElementById("firstName").attributes.getNamedItem("placeholder").value = "Joe";
                    document.getElementById("firstName").classList.remove("invalid-form");
                });
    
                document.getElementById("lastName").addEventListener('focus', (e) => {
                    document.getElementById("lastName").style = "border: 1px solid #E1E1E1";
                    document.getElementById("lastName").attributes.getNamedItem("placeholder").value = "Doe";
                    document.getElementById("lastName").classList.remove("invalid-form");
                });
    
                document.getElementById("phone").addEventListener('focus', (e) => {
                    document.getElementById("phone").style = "border: 1px solid #E1E1E1; color #000000;";
                    document.getElementById("phone").attributes.getNamedItem("placeholder").value = "777-777-7777";
                    document.getElementById("phone").classList.remove("invalid-form");
                });
    
                document.getElementById("password").addEventListener('focus', (e) => {
                    document.getElementById("password").style = "border: 1px solid #E1E1E1; color #000000;";
                    document.getElementById("password").attributes.getNamedItem("placeholder").value = "mySecurePassword!8";
                    if (isMobile) {
                        document.getElementById("password").classList.remove("invalid-form-mobile");
                    }
                    else {
                        document.getElementById("password").classList.remove("invalid-form");
                    }
    
                });
    
                document.getElementById("confirmPassword").addEventListener('focus', (e) => {
                    document.getElementById("confirmPassword").style = "border: 1px solid #E1E1E1; color #000000;";
                    document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value = "mySecurePassword!8";
                    if (isMobile) {
                        document.getElementById("confirmPassword").classList.remove("invalid-form-mobile");
                    }
                    else {
                        document.getElementById("confirmPassword").classList.remove("invalid-form");
                    }
    
                });
            }
        })

        if(orderID.length === 0){
            generateOrderId();
        }

        switch (cartItems.subType) {
            case "$599.99 / year":
                var withoutStripePercentageYearly = 599.99 * parseFloat(quantity)

                setCartTotal(parseFloat(withoutStripePercentageYearly + (18.0 * quantity)));
                break;
            case "$64.99 / month":
                var withoutStripePercentageMonthly = 64.99 * parseFloat(quantity)
                setCartTotal(parseFloat(withoutStripePercentageMonthly + (3.0 * quantity)));
                break;
            default:
                break;
        }

        return () => {
            document.removeEventListener('focus', document.getElementById("email"));
            document.removeEventListener('focus', document.getElementById("firstName"));
            document.removeEventListener('focus', document.getElementById("lastName"));
            document.removeEventListener('focus', document.getElementById("phone"));
            document.removeEventListener('focus', document.getElementById("password"));
            document.removeEventListener('focus', document.getElementById("confirmPassword"));
            unsub();
        }

    }, [isMobile, cartItems, cartItems.amt, cartItems.subType, orderID.length]);

    function addCommas(num) {
        // Split the number into integer and decimal parts
        let parts = num.toString().split(".");
    
        // Add commas to the integer part
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
        // Round the decimal part to 2 digits, if it exists
        if (parts[1]) {
            parts[1] = Math.round(parseFloat("0." + parts[1]) * 100).toString().padStart(2, '0');
        }
    
        // Join the parts back together
        return parts.join(".");
    }
    

    function validateForm(e) {

        setProcessing(true);

        if (loginEmail.length !== 0 && loginPassword.length !== 0) {
            console.log("SIGNING IN EXISTING USER")
            signInUser(loginEmail, loginPassword);
            return;
        }

        if (isAuthed) {
            launchStripeCheckout(auth.currentUser, cartItems.amt);
            return;
        }

        let emptyCheck = false;

        if (email.length === 0 && fName.length === 0 && lName.length === 0){
            alert("Please log in or create an account before purchasing.")
        }

        if (email.length === 0) {
            document.getElementById("email").style = "border: 1px solid red;";
            document.getElementById("email").attributes.getNamedItem("placeholder").value = "Please enter a valid email address";
            document.getElementById("email").classList.add("invalid-form");
            emptyCheck = true;
        }

        if (fName.length === 0) {
            document.getElementById("firstName").style = "border: 1px solid red;";
            document.getElementById("firstName").attributes.getNamedItem("placeholder").value = "Please enter a first name";
            document.getElementById("firstName").classList.add("invalid-form");
            emptyCheck = true;
        }

        if (lName.length === 0) {
            document.getElementById("lastName").style = "border: 1px solid red;";
            document.getElementById("lastName").attributes.getNamedItem("placeholder").value = "Please enter a last name";
            document.getElementById("lastName").classList.add("invalid-form");
            emptyCheck = true;
        }

        if (emptyCheck) {
            setProcessing(false);
            return;
        }

        if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
            console.log("VALID EMAIL");
            if (phone.length !== 0) {
                if (/^\d{3}-\d{3}-\d{4}$/.test(phone) || /^\d{10}$/.test(phone)) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

                    if (passwordRegex.test(password)) {
                        if (passwordRegex.test(confirmPassword)) {
                            if (password === confirmPassword) {
                                createUserAccount();
                            }
                            else {
                                document.getElementById("password").style = "border: 1px solid red; color: red;";
                                document.getElementById("password").attributes.getNamedItem("placeholder").value = "Passwords do not match.";
                                setPassword("");
                                document.getElementById("password").classList.add("invalid-form");

                                document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                                document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value = "Passwords do not match.";
                                setConfirmPassword("");
                                document.getElementById("confirmPassword").classList.add("invalid-form");
                                setProcessing(false);
                            }
                        }
                        else {
                            document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                            document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                            setConfirmPassword("");
                            if (isMobile) {
                                document.getElementById("confirmPassword").classList.add("invalid-form-mobile");
                            }
                            else {
                                document.getElementById("confirmPassword").classList.add("invalid-form");

                            }
                            setProcessing(false);
                        }
                    } else {
                        document.getElementById("password").style = "border: 1px solid red; color: red;";
                        document.getElementById("password").attributes.getNamedItem("placeholder").value = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                        setPassword("");
                        if (isMobile) {
                            document.getElementById("password").classList.add("invalid-form-mobile");
                        }
                        else {
                            document.getElementById("password").classList.add("invalid-form");
                        }
                        setProcessing(false);
                    }
                }
                else {
                    document.getElementById("phone").style = "border: 1px solid red; color: red;";
                    document.getElementById("phone").attributes.getNamedItem("placeholder").value = "Phone number should follow this format: XXX-XXX-XXXX";
                    document.getElementById("phone").classList.add("invalid-form");
                    setProcessing(false);
                    return;
                }
            }
            else {
                if (/^\d{3}-\d{3}-\d{4}$/.test(phone) || /^\d{10}$/.test(phone)) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

                    if (passwordRegex.test(password)) {
                        if (passwordRegex.test(confirmPassword)) {
                            if (password === confirmPassword) {
                                createUserAccount();
                            }
                            else {
                                document.getElementById("password").style = "border: 1px solid red; color: red;";
                                document.getElementById("password").attributes.getNamedItem("placeholder").value = "Passwords do not match.";
                                setPassword("");
                                document.getElementById("password").classList.add("invalid-form");

                                document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                                document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value = "Passwords do not match.";
                                setConfirmPassword("");
                                document.getElementById("confirmPassword").classList.add("invalid-form");
                                setProcessing(false);
                            }
                        }
                        else {
                            document.getElementById("confirmPassword").style = "border: 1px solid red; color: red;";
                            document.getElementById("confirmPassword").attributes.getNamedItem("placeholder").value = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                            setConfirmPassword("");
                            if (isMobile) {
                                document.getElementById("confirmPassword").classList.add("invalid-form-mobile");
                            }
                            else {
                                document.getElementById("confirmPassword").classList.add("invalid-form");

                            }
                            setProcessing(false);
                        }
                    } else {
                        document.getElementById("password").style = "border: 1px solid red; color: red;";
                        document.getElementById("password").attributes.getNamedItem("placeholder").value = "Must be 8 characters long, have 1 uppercase letter, 1 lowercase letter, and 1 digit.";
                        setPassword("");
                        if (isMobile) {
                            document.getElementById("password").classList.add("invalid-form-mobile");
                        }
                        else {
                            document.getElementById("password").classList.add("invalid-form");
                        }
                        setProcessing(false);
                    }
                }
                else {
                    document.getElementById("phone").style = "border: 1px solid red; color: red;";
                    document.getElementById("phone").attributes.getNamedItem("placeholder").value = "Phone number should follow this format: XXX-XXX-XXXX";
                    document.getElementById("phone").classList.add("invalid-form");
                    setProcessing(false);
                    return;
                }
            }
        }
        else {
            document.getElementById("email").style = "border: 1px solid red; color: red";
            document.getElementById("email").attributes.getNamedItem("placeholder").value = "Please enter a valid email address";
            document.getElementById("email").classList.add("invalid-form");
            setProcessing(false);
            return;
        }
    }

    function createUserAccount() {
        createUserWithEmailAndPassword(auth, email.toLowerCase(), password).then((userCredential) => {
            signInNewUser(email.toLowerCase(), password);
        })
            .catch((error) => {
                
                if(error.code === "auth/email-already-in-use"){
                    alert("Email already in use. Please try signing in.");
                }
                else if(error.code === "auth/network-request-failed"){
                    alert("An unexpected network error occurred. Please try again.");
                }
                else{
                    alert("An unexpected error occurred. Please try again.");
                }

                setProcessing(false);

            });
    }

    function signInNewUser(emailSelected, passwordSelected) {
        signInWithEmailAndPassword(auth, emailSelected, passwordSelected).then((userCredential) => {
            //console.log("SIGN IN SUCCESSFUL");
            const user = userCredential.user;
            
            ReactGA.event({
                category: "login_checkout",
                action: "User logged in.",
            });

            setDoc(doc(database, "users", `${user.uid}`),
                {
                    userID: user.uid, email: email, tel: phone, firstName: fName, lastName: lName, company: company, pfp: "",
                }).then(async () => {
                    //console.log("DOCUMENT SUCCESSFULLY ADDED TO DB");

                    ReactGA.event({
                        category: "begin_checkout",
                        action: "User logged in and user doc created. Launching Stripe checkout.",
                    });

                    launchStripeCheckout(user);
                })
                .catch((error) => {

                    if (error.code === "auth/network-request-failed") {
                        alert("An error occured initializing your account. Please check your internet connection and try again.");

                        ReactGA.event({
                            category: "network_failure_checkout",
                            action: `Network error occured trying to create a user document: ${error}`,
                        });

                        signOutCurrentUser();
                    }
                    else {
                        alert("An unexpected error occurred. Please try again.");

                        ReactGA.event({
                            category: "doc_creation_error_checkout",
                            action: `An error occured trying to create a user document: ${error}`,
                        });
                        
                        signOutCurrentUser();
                    }

                    setProcessing(false);

                });
        })
            .catch((error) => {

                if (error.code === "auth/account-exists-with-different-credential") {
                    alert("You're account does not use Google Sign In. Please try a different sign-in method.");

                    ReactGA.event({
                        category: "login_error_checkout",
                        action: `User attempted to login with alternate sign-in method: ${error}`,
                    });
                }
                else if (error.code === "auth/network-request-failed") {
                    alert("An error occured signing into your account. Please check your internet connection and try again.");

                    ReactGA.event({
                        category: "network_failure_checkout",
                        action: `Network error occured trying to login a user: ${error}`,
                    });

                }
                else {
                    alert("An unexpected error occurred. Please try again.");

                    ReactGA.event({
                        category: "login_error_checkout",
                        action: `An error occured trying to login a user: ${error}`,
                    });
                }

                setProcessing(false);
            });
    }

    function signInUser(emailSelected, passwordSelected) {
        signInWithEmailAndPassword(auth, emailSelected, passwordSelected).then(async (userCredential) => {
            //console.log("SIGN IN SUCCESSFUL");
            const user = userCredential.user;

            ReactGA.event({
                category: "login_checkout",
                action: "User logged in.",
            });

            if(getDoc(doc(database, "users", `${userCredential.user.uid}`)).exists()){
                //console.log("USER DOES EXIST");

                ReactGA.event({
                    category: "begin_checkout",
                    action: "User logged in and user doc exists. Launching Stripe checkout.",
                });

                launchStripeCheckout(userCredential.user);
            }
            else{
                //console.log("USER DOES NOT EXIST");

                setDoc(doc(database, "users", `${user.uid}`),
                {
                    userID: user.uid, email: email, tel: phone, firstName: fName, lastName: lName, company: company, pfp: "",
                }).then(async () => {
                    //console.log("DOCUMENT SUCCESSFULLY ADDED TO DB");

                    ReactGA.event({
                        category: "begin_checkout",
                        action: "User logged in and user doc created. Launching Stripe checkout.",
                    });

                    launchStripeCheckout(user);
                    setProcessing(false);
                })
                .catch((error) => {

                    if (error.code === "auth/network-request-failed") {
                        alert("An error occured initializing your account. Please check your internet connection and try again.");

                        ReactGA.event({
                            category: "network_failure_checkout",
                            action: `Network error occured trying to create a user document: ${error}`,
                        });

                        signOutCurrentUser();
                    }
                    else {
                        alert("An unexpected error occurred. Please try again.");

                        ReactGA.event({
                            category: "doc_creation_error_checkout",
                            action: `An error occured trying to create a user document: ${error}`,
                        });

                        signOutCurrentUser();
                    }
                    setProcessing(false);
                });
            }
            //console.log(user.uid);
        })
            .catch((error) => {

                if (error.code === "auth/user-not-found") {
                    alert("User Account not found. Please try again.");

                    ReactGA.event({
                        category: "login_error_checkout",
                        action: `An error occured trying to sign in a user: ${error}`,
                    });
                }
                else if (error.code === "auth/wrong-password") {
                    alert("Incorrect password. Please try again.");

                    ReactGA.event({
                        category: "invalid_password_error_checkout",
                        action: `Incorrect password attempt: ${error}`,
                    });
                }

                setProcessing(false);

            });
    }

    function googleSignIn() {
        signInWithPopup(auth, new GoogleAuthProvider())
            .then((result) => {
                const user = result.user;
                const firstName = user.displayName.split(" ")[0];
                const lastName = user.displayName.split(" ")[1];

                ReactGA.event({
                    category: "login_checkout",
                    action: "User logged in.",
                });

                setDoc(doc(database, "users", `${user.uid}`),
                    {
                        userID: user.uid, email: user.email, tel: user.phoneNumber, firstName: firstName, lastName: lastName, company: "", pfp: user.photoURL,
                    }).then(async () => {
                        ReactGA.event({
                            category: "begin_checkout",
                            action: "User logged in and user doc created. Launching Stripe checkout.",
                        });
                        showSuccessfulLogin();
                    })
                    .catch((error) => {

                        if (error.code === "auth/network-request-failed") {
                            alert("An error occured initializing your account. Please check your internet connection and try again.");

                            ReactGA.event({
                                category: "network_failure_checkout",
                                action: `Network error occured trying to create a user document: ${error}`,
                            });

                            signOutCurrentUser();
                        }
                        else {
                            alert("An unexpected error occurred. Please try again.");

                            ReactGA.event({
                                category: "doc_creation_error_checkout",
                                action: `An error occured trying to create a user document: ${error}`,
                            });

                            signOutCurrentUser();
                        }
                        
                        setProcessing(false);

                    });
            }).catch((error) => {

                if (error.code === "auth/account-exists-with-different-credential") {
                    alert("You're account does not use Google Sign In. Please try a different sign-in method.");

                    ReactGA.event({
                        category: "google_login_error_checkout",
                        action: `Incorrect sign-in method used: ${error}`,
                    });

                }
                else if (error.code === "auth/popup-closed-by-user") {
                    alert("To sign-in with Google, you must allow the popup to open.");

                    ReactGA.event({
                        category: "google_popup_error_checkout",
                        action: `Popup closed by user: ${error}`,
                    });

                }
                else if (error.code === "auth/cancelled-popup-request") {
                    alert("To sign-in with Google, you must allow the popup to open.");

                    ReactGA.event({
                        category: "google_popup_error_checkout",
                        action: `Popup cancelled by user: ${error}`,
                    });

                }
                else if (error.code === "auth/network-request-failed") {
                    alert("An error occured signing into your account. Please check your internet connection and try again.");

                    ReactGA.event({
                        category: "network_failure_checkout",
                        action: `Network error occured trying to create a user document: ${error}`,
                    });
                }
                else {
                    alert("An unexpected error occurred. Please try again.");

                    ReactGA.event({
                        category: "google_signin_error_checkout",
                        action: `An error occured trying to sign in a user using Google: ${error}`,
                    });
                }

                setProcessing(false);
            });
    }

    /*function googleRedirectSignIn(){
        signInWithRedirect(auth, new GoogleAuthProvider()).then(()=>{
            getRedirectResult(auth).then((result)=>{
                const user = result.user;
                const firstName = user.displayName.split(" ")[0];
                const lastName = user.displayName.split(" ")[1];
                setDoc(doc(database, "users", `${user.uid}`), 
                    {userID: user.uid, email: user.email, tel: user.phoneNumber, firstName: firstName, lastName: lastName, company: "", pfp: user.photoURL,
                }).then(async()=>{
                    //console.log("DOCUMENT SUCCESSFULLY ADDED TO DB");
                    showSuccessfulLogin();
                })
                .catch((error)=>{
                    console.log(error);
                    console.log(error.code);
                    console.log(error.message);
                });
            })
            .catch((error) => {
                // Handle Errors here.
                //const errorCode = error.code;
                //const errorMessage = error.message;
                console.log(error.code)
                console.log(error.message);
                // The email of the user's account used.
                //const email = error.customData.email;
    
                // The AuthCredential type that was used.
                //const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
        })
    }*/

    function generateOrderId() {

        function getRandomChar() {
            const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            return chars.charAt(Math.floor(Math.random() * chars.length));
        }
        function generatePart() {
            let part = '';
            for (let i = 0; i < 5; i++) {
                part += getRandomChar();
            }
            return part;
        }

        const part1 = generatePart();
        const part2 = generatePart();
        const part3 = generatePart();

        // Combine the parts into the final format
        setOrderID(`${part1}-${part2}-${part3}`);
    }

    function fetchUserInformation() {
        return new Promise(async (resolve, reject) => {
            await getDoc(doc(database, "users", auth.currentUser.uid)).then((data) => {
                setFName(data.data().firstName);
                setLName(data.data().lastName);
                setEmail(data.data().email);
                setGooglePFP(data.data().pfp);
                resolve(200);
            }).catch((err) => {
                resolve(500);
            })
        })
    }

    async function launchStripeCheckout(user) {
        await createCheckoutSession(window.location.origin + "/orderConfirmation", window.location.href, user.uid, cartItems.amt, orderID, cartItems.productName, `${cartItems.subType.split("/")[1]}ly`, cartTotal);
    }

    function signOutCurrentUser() {
        auth.signOut();
    }

    function showSuccessfulLogin() {
        setLoginSuccessModal(true);
        setTimeout(() => {
            setLoginSuccessModal(false);
        }, 10000);
    }

    function showHidePassword() {
        setPasswordVisibility(!passwordVisibility);
    }

    function showHideConfirmPassword() {
        setConfirmPasswordVisibility(!confirmPasswordVisibility);
    }

    function showHideLoginPassword() {
        setLoginPasswordVisibility(!loginPasswordVisibility);
    }

    function rotateArrow() {
        if (document.getElementById("drop-down-arrow").style.rotate === "180deg") {
            document.getElementById("drop-down-arrow").style = `rotate:0deg; width: ${isMobile ? "8%" : "5%"}; cursor: pointer;`;
        }
        else {
            document.getElementById("drop-down-arrow").style = `rotate:180deg; width: ${isMobile ? "8%" : "5%"}; cursor: pointer;`;
        }
    }

    return (
        <div>
            {console.log(isAuthed)}
            {loginSuccess &&
                <div id={isMobile ? "logged-in-modal-mobile" : "logged-in-modal-desktop"}>
                    <p>Succesfully Logged In</p>
                    <img src={successIcon} alt='Green Circle with White Checkmark in the center' />
                </div>
            }
            <header id="header">
                {!isMobile &&
                    <nav className={"store-nav-desktop"}>
                        <Link to={"/store"} id="nav-logo-desktop" style={{ paddingLeft: 16 }}>
                            <img alt="Transparent Software Logo" src={initialsLogo} />
                        </Link>
                    </nav>
                }
                {isMobile &&
                    <nav className={"store-nav-mobile"}>
                        <Link to={"/store"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile" />
                        </Link>
                    </nav>
                }
            </header>
            <main id={isMobile ? "main-mobile" : "main-desktop"}>
                <div id={isMobile ? "checkout-container-mobile" : "checkout-container-desktop"}>
                    <div id={isMobile ? "info-side-container-mobile" : "info-side-container-desktop"}>
                        <h2>Checkout</h2>
                        {isAuthed && isLoading &&
                            <div>
                                <h3>Signed in as:</h3>
                                <ProfileBanner firstName={fName} lastName={lName} email={email} pfp={googlePFP} profileRetrieveError={profileRetrieveError} signOutUser={signOutCurrentUser} />
                            </div>
                        }
                        {!isAuthed &&
                            <motion.form animate={{ opacity: [0, 1], transition: { duration: 0.75 } }}>
                                <Collapsible trigger={<div style={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between", alignItems: "center" }}><h3 style={{ margin: 0, padding: 0 }}>Create Account</h3><img id="drop-down-arrow" alt="Collapsible Arrow" src={downArrow} onClick={rotateArrow} style={{ width: isMobile ? "8%" : "5%", cursor: "pointer" }} /></div>} easing={"ease-in-out"}>
                                    <div id={isMobile ? "form-names-container-mobile" : "form-names-container-desktop"}>
                                        <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                            <label for="firstName">First Name*:</label>
                                            <input id="firstName" type="text" placeholder='Joe' onChange={(e) => { setFName(e.target.value); }} />
                                        </div>
                                        <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"} style={{ marginRight: 0, }}>
                                            <label for="lastName">Last Name*:</label>
                                            <input id="lastName" type="text" placeholder='Doe' onChange={(e) => { setLName(e.target.value); }} />
                                        </div>
                                    </div>
                                    <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                        <label for="company">Company:</label>
                                        <input id="company" type="text" placeholder='Company Name' onChange={(e) => { setCompany(e.target.value); }} />
                                    </div>
                                    <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                        <label for="phone">Phone:</label>
                                        <input id="phone" type="tel" placeholder='777-777-7777' value={phone} onChange={(e) => { setPhone(e.target.value); }} />
                                    </div>
                                    <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                        <label for="email">Email*:</label>
                                        <input id="email" type="email" placeholder='doe.joe@gmail.com' value={email} onChange={(e) => { setEmail(e.target.value); }} />
                                    </div>
                                    <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                        <label for="password">Password*:</label>
                                        <div className={isMobile ? "password-input-container-mobile" : "password-input-container-desktop"}>
                                            <input id="password" type={passwordVisibility ? "text" : "password"} placeholder='mySecurePassword8!' value={password} onChange={(e) => { setPassword(e.target.value); }} />
                                            <motion.img whileHover={{ opacity: [1, 0.5] }} src={passwordVisibility ? showPasswordIcon : hidePasswordIcon} onClick={showHidePassword} />
                                        </div>
                                    </div>
                                    <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                        <label for="confirmPassword">Confirm Password*:</label>
                                        <div className={isMobile ? "password-input-container-mobile" : "password-input-container-desktop"}>
                                            <input id="confirmPassword" type={confirmPasswordVisibility ? "text" : "password"} hidden={false} placeholder='mySecurePassword8!' value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); }} />
                                            <motion.img whileHover={{ opacity: [1, 0.5] }} src={confirmPasswordVisibility ? showPasswordIcon : hidePasswordIcon} onClick={showHideConfirmPassword} />
                                        </div>
                                    </div>
                                </Collapsible>
                                <h4>or</h4>
                            </motion.form>
                        }
                        {!isAuthed &&
                            <motion.form animate={{ opacity: [0, 1], transition: { duration: 0.75 } }}>
                                <div style={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between", alignItems: "center", paddingTop: "5%" }}>
                                    <h3 style={{ margin: 0, padding: 0, }}>Sign In</h3>
                                    <button type="button" className={isMobile ? "google-sign-in-button-mobile" : "google-sign-in-button"} onClick={googleSignIn}>
                                        Sign in with Google
                                    </button>
                                </div>
                                <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                    <label for="email-login">Email:</label>
                                    <input id="email-login" type="email" placeholder='doe.joe@gmail.com' value={loginEmail} onChange={(e) => { setLoginEmail(e.target.value); }} />
                                </div>
                                <div className={isMobile ? "form-item-container-mobile" : "form-item-container-desktop"}>
                                    <label for="password-login">Password:</label>
                                    <div className={isMobile ? "password-input-container-mobile" : "password-input-container-desktop"}>
                                        <input id="password-login" type={loginPasswordVisibility ? "text" : "password"} placeholder='mySecurePassword8!' value={loginPassword} onChange={(e) => { setLoginPassword(e.target.value); }} />
                                        <motion.img whileHover={{ opacity: [1, 0.5] }} src={loginPasswordVisibility ? showPasswordIcon : hidePasswordIcon} onClick={showHideLoginPassword} />
                                    </div>
                                </div>
                            </motion.form>
                        }
                    </div>
                    <div id={isMobile ? "total-side-container-mobile" : "total-side-container-desktop"}>
                        <h2>Items</h2>
                        <img src={poweredByStripe} id={isMobile ? "stripe-logo-mobile" : "stripe-logo-desktop"} alt="Powered by Stripe Iconography" />
                        <div id={isMobile ? "items-container-mobile" : "items-container-desktop"}>
                            <ProductCheckoutItem name={cartItems.productName} quantity={cartItems.amt} subscription={cartItems.subType}/>
                            <div id={isMobile ? "items-total-container-mobile" : "items-total-container-desktop"}>
                                <div className={isMobile ? "totals-line-container-mobile" : "totals-line-container-desktop"}>
                                    <h4 style={{fontWeight: 400}}>Stripe Processing Fee</h4>
                                    <p style={{fontSize: isMobile ? '4.15vw' : '0.75vw'}}>${cartItems.subType.split("/")[1].includes("year") ? 18.00*cartItems.amt : 3.00*cartItems.amt}.00</p>
                                </div>
                                <div className={isMobile ? "totals-line-container-mobile" : "totals-line-container-desktop"} style={{borderTop: "1px solid #000000"}}>
                                    <h3>Total:</h3>
                                    <p>${addCommas(cartTotal)} / {cartItems.subType.split("/")[1]}</p>
                                </div>
                            </div>
                        </div>
                        <motion.button type="submit" onClick={validateForm} whileHover={{ opacity: [1, 0.75] }} id={isMobile ? "pay-total-button-mobile" : "pay-total-button-desktop"} style={{ paddingBlock: isMobile && processing ? "3%" : undefined }}>
                            {!processing && <p>Pay Total</p>}
                            {processing && <div className="loader"></div>}
                        </motion.button>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Checkout