import React, {useEffect} from 'react'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom';

export default function RedditADS() {
    ReactGA.initialize("G-SZLZLTXLRV");
    const navigate = useNavigate()

    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/redditredirect",
            title: "Reddit AD"
        });

        navigate("/clashmarker");

    })

    return (
        <div></div>
    )
}
