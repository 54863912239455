import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

//Routes
import App from './App';
import ContactUs from './Routes/ContactUs'
import ProductPageMindful from './Routes/ProductPageMindful';
import ErrorPage from './Routes/ErrorPage';
import StoreFront from './Routes/StoreFront';
import Checkout from './Routes/Checkout'
import OrderConfirmation from './Routes/OrderConfirmation';
import Profile from './Routes/Profile';
import Legal from './Routes/Legal';
import CreateAccount from './Routes/CreateAccount';
import ProductPageClashMarker from './Routes/ProductPageClashMarker';
import FAQ from './Routes/FAQ';
import Unsubscribe from './Routes/Unsubscribe'
import FreeTrial from './Routes/FreeTrial';
import RedditADS from './Routes/RedditADS';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/contactus",
    element: <ContactUs/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/mindful",
    element: <ProductPageMindful/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/clashmarker",
    element: <ProductPageClashMarker/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/store",
    element: <StoreFront/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: "/store/checkout",
    element: <Checkout/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: "/orderConfirmation",
    element: <OrderConfirmation/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/store/profile",
    element: <Profile/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/store/createAccount",
    element: <CreateAccount/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/faq",
    element: <FAQ/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/legal",
    element: <Legal/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/unsubscribe",
    element: <Unsubscribe/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/clashmarkerfreetrial",
    element: <FreeTrial/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/redditredirect",
    element: <RedditADS/>,
    errorElement: <ErrorPage/>
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
